<template>
  <div class="">
    <div v-show="getClientType==='private'" class="space-y-4">
      <div class="flex justify-around space-x-8">
        <InputText class="mt-2 w-full" v-model="step2.private.client_id" :isError="errors.private.client_id.invalid"
                   :errorMessage="errors.private.client_id.message"
                   :label="$t('clients.attrs.id')" :isRequired="true"
                   :isOnlyNumber="true"
                   :maxLength="13"
                   placeholder=""/>
        <InputText class="mt-2 w-full" v-model="step2.private.title"
                   :label="$t('clients.attrs.title')"
                   placeholder=""/>
      </div>
      <div class="flex justify-around space-x-8 ">
        <InputSelect class="mt-2 w-full"
                     :label="$t('general.attrs.salutation')"
                     :options="$t('general.salutation_with_none')"
                     :isError="errors.private.salutation.invalid"
                     :errorMessage="errors.private.salutation.message"
                     :isRequired="true"
                     v-model="step2.private.salutation"
                     placeholder=""/>
        <InputText class="mt-2 w-full " v-model="step2.private.first_name"
                   :isError="errors.private.first_name.invalid"
                   :errorMessage="errors.private.first_name.message"
                   :label="$t('general.attrs.first_name')"
                   :isRequired="true"
                   placeholder=""/>
        <InputText class="mt-2 w-full " v-model="step2.private.last_name" :isError="errors.private.last_name.invalid"
                   :errorMessage="errors.private.last_name.message"
                   :label="$t('general.attrs.last_name')"
                   :isRequired="true"
                   placeholder=""/>
      </div>
      <div class="flex justify-around space-x-8 ">
        <InputText class="mt-2 w-full" v-model="step2.private.tax_number"
                   @input="formatTaxNumberStep2"
                   :label="$t('clients.attrs.tax_number')"
                   :isOnlyNumber="true"
                   :showHelpIconPopover="true"
                   :popoverTexts="popoverTexts_tax_number"
                   :popoverLink="popoverLink_tax_number"
                   placeholder=""/>
        <InputText class="mt-2 w-full "
                   v-model="step2.private.client_number"
                   :label="$t('clients.attrs.client_number')"
                   :isOnlyNumber="true"
                   :maxLength="11"
                   placeholder=""/>
        <InputDate class="mt-2 w-full dob" v-model="step2.private.dob"
                   :label="$t('clients.attrs.dob')"
                   :dob="true"
                   placeholder=""/>
      </div>
      <div class="flex justify-around space-x-8 ">
        <InputSelect
            class="w-full"
            :label="$t('settings.powers_of_attorney.default_power_of_attorney')"
            :options="powersOfAttorneyList"
            v-model="step2.power_of_attorney"
            :selected-value="step2.power_of_attorney"
            placeholder=""
        />
        <div class="w-full invisible" />
        <div class="w-full invisible" />
      </div>
      <div class="flex justify-end space-x-4 mt-16  mb-12">
        <Button class="w-52" :text="$t('buttons.back')" @click="goBack" :secondary="true"/>
        <Button class="w-52" :text="$t('buttons.next')" @click="submitNext"/>
      </div>
    </div>
    <div v-show="getClientType==='business'" class="space-y-4">
      <div class="flex justify-around space-x-8">
        <InputText class="mt-2 w-full" v-model="step2.business.client_id"
                   :isError="this.errors.business.client_id.invalid"
                   :errorMessage="this.errors.business.client_id.message"
                   :label="$t('clients.attrs.id')" :isRequired="true"
                   :isOnlyNumber="true"
                   :maxLength="13"
                   placeholder=""/>
        <InputSelect class="mt-2 w-full" v-model="step2.business.legal_form"
                     :label="$t('clients.attrs.legal_form')"
                     :isCategory="true"
                     :filterable="true"
                     :options="$t('clients.legal_forms')"
                     placeholder=""/>
      </div>
      <div class="flex justify-around space-x-8 ">
        <InputText class="w-full " v-model="step2.business.company_name"
                   :isError="this.errors.business.company_name.invalid"
                   :errorMessage="this.errors.business.company_name.message"
                   :label="$t('general.attrs.name_company')"
                   :isRequired="true"
                   :maxLength="50"
                   placeholder=""/>
        <InputSelect class=" w-full" v-model="step2.business.salutation"
                     :isError="this.errors.business.salutation.invalid"
                     :errorMessage="this.errors.business.salutation.message"
                     :label="$t('general.attrs.salutation')" :isRequired="true"
                     :options="$t('clients.business_salutation')"
                     placeholder=""/>
      </div>
      <div class="flex justify-around space-x-8 ">
        <InputText class=" w-full" v-model="step2.business.vat_number_wot_code"
                   :label="$t('clients.attrs.vat_number_wot_country')"
                   :isOnlyNumber="true"
                   placeholder=""/>
        <InputSelect class=" w-full " v-model="step2.business.vat_country_code"
                     :label="$t('clients.attrs.vat_country_code')"
                     :selected-value="step2.business.vat_country_code"
                     :options="getVatCountryCodes()"
                     placeholder=""/>
      </div>
      <div class="flex justify-around space-x-8 ">
        <InputText class=" w-1/2" v-model="step2.business.tax_number"
                   :label="$t('clients.attrs.tax_number')"
                   :isOnlyNumber="true"
                   placeholder=""/>
        <InputSelect
            class="w-1/2"
            :label="$t('settings.powers_of_attorney.default_power_of_attorney')"
            :options="powersOfAttorneyList"
            v-model="step2.power_of_attorney"
            :selected-value="step2.power_of_attorney"
            placeholder=""
        />
      </div>

      <div class="flex justify-end space-x-4 mt-6 mb-6">
        <Button class="w-52" :text="$t('buttons.back')" @click="goBack" :secondary="true"/>
        <Button class="w-52" :text="$t('buttons.next')" @click="submitNext"/>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {requiredIf} from "vuelidate/lib/validators";
import InputDate from "../../inputs/InputDate";
import InputSelect from "../../inputs/InputSelect";
import {PowersOfAttorneyService} from "@/services/powersofattorney.service";
import {SearchService} from '@/services/search.service';

export default {
  name: "Step2",
  components: {InputSelect, InputDate},
  data() {
    return {
      popoverTexts_tax_number: [
        this.$t('clients.tooltips.step2.tax_number1'),
        this.$t('clients.tooltips.step2.tax_number2'),
      ],
      popoverLink_tax_number: "https://hilfe.grundsteuer-digital.de/faq/steuernummer-privatperson-unternehmen/",
      countries: [],
      countryCodes: [{
        "name": "DE",
        "code": "DE"
      }],
      step2: {
        power_of_attorney: "",
        private: {
          client_id: this.$store.state.client.clientData.private.client_id,
          title: this.$store.state.client.clientData.private.title,
          salutation: this.$store.state.client.clientData.private.salutation,
          first_name: this.$store.state.client.clientData.private.first_name,
          last_name: this.$store.state.client.clientData.private.last_name,
          tax_number: this.$store.state.client.clientData.private.tax_number,
          client_number: this.$store.state.client.clientData.private.client_number,
          dob: this.$store.state.client.clientData.private.dob
        },
        business: {
          client_id: this.$store.state.client.clientData.business.client_id,
          legal_form: this.$store.state.client.clientData.business.legal_form,
          salutation: this.$store.state.client.clientData.business.salutation,
          company_name: this.$store.state.client.clientData.business.company_name,
          vat_number_wot_code: this.$store.state.client.clientData.business.vat_number_wot_code,
          vat_country_code: this.$store.state.client.clientData.business.vat_country_code ? this.$store.state.client.clientData.business.vat_country_code : "DE",
          tax_number: this.$store.state.client.clientData.business.tax_number
        }
      },
      errors: {
        private: {
          client_id: {
            invalid: false,
            message: this.$t('clients.errors.required_client_id')
          },
          salutation: {
            invalid: false,
            message: this.$t('clients.errors.required_salutation')
          },
          first_name: {
            invalid: false,
            message: this.$t('clients.errors.required_first_name')
          },
          last_name: {
            invalid: false,
            message: this.$t('clients.errors.required_last_name')
          }
        },
        business: {
          client_id: {
            invalid: false,
            message: this.$t('clients.errors.required_client_id')
          },
          salutation: {
            invalid: false,
            message: this.$t('clients.errors.required_salutation')
          },
          company_name: {
            invalid: false,
            message: this.$t('clients.errors.required_company_name')
          }
        }
      },
      powersOfAttorneyList: [],
    }
  },
  watch: {
    step2: {
      handler(val) {
        setTimeout(function () {
          if(val.private.tax_number) {
            val.private.tax_number = val.private.tax_number.toString().substring(0, 13).replace(/[A-Z]/gi, '')
          }
        }, 300)
      },
      deep: true,
    }
  },
  validations: {
    step2: {
      private: {
        client_id: {
          required: requiredIf(function () {
            return this.getClientType === 'private';
          })
        },
        salutation: {
          required: requiredIf(function () {
            return this.getClientType === 'private';
          })
        },
        first_name: {
          required: requiredIf(function () {
            return this.getClientType === 'private';
          })
        },
        last_name: {
          required: requiredIf(function () {
            return this.getClientType === 'private';
          })
        }
      }, business: {
        client_id: {
          required: requiredIf(function () {
            return this.getClientType === 'business';
          })
        },
        salutation: {
          required: requiredIf(function () {
            return this.getClientType === 'business';
          })
        },
        company_name: {
          required: requiredIf(function () {
            return this.getClientType === 'business';
          })
        },
      }
    },
  },
  computed: {
    ...mapGetters("client", ["getClientData"]),
    getClientType() {
      return this.getClientData.clientType;
    }
  },
  mounted() {
    this.$api.get_country_list().then((countryList) => {
      this.countries = countryList;
    });

    let powersOfAttorneyService = new PowersOfAttorneyService();
    powersOfAttorneyService.listAll().then((list) => {
      this.powersOfAttorneyList = list.map((o) => {
        return {
          "name": o.formattedName,
          "code": o.id
        }
      });
    });
  },
  methods: {
    ...mapMutations("client", ["setClientData"]),
    submitNext() {
      // Reset validation status
      for (let type in this.errors) {
        for (let attr in this.errors[type]) {
          this.errors[type][attr].invalid = false;
        }
      }
      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        for (let type in this.errors) {
          for (let attr in this.errors[type]) {
            this.errors[type][attr].invalid = this.$v.step2[type][attr].$error;
          }
        }
        return;
      }

      let clientName = this.step2.private.last_name + ', ' + this.step2.private.first_name;
      (new SearchService()).searchClient(this.step2.private.last_name, -1, false).then((result) => {
        if (result.length === 0) {
          this.goToNextStep();
          return;
        }

        result.forEach((o) => {
          if (o.client_name !== clientName || o.vat_id === this.step2.private.tax_number) {
            this.goToNextStep();
            return;
          }

          const numberName = this.isBusiness()
            ? this.$t('clients.add_confirm_client_number_name_business')
            : this.$t('clients.add_confirm_client_number_name_tax_consultant');

          this.$modal.show({
            headerText: '',
            text: this.$t(
              'clients.add_confirm',
              {
                number_name: numberName,
                client_number:  o.client_id,
              }
            ),
            showClose: true,
            canEscape: false,
            canCloseOutsideClick: false,
            buttons: {
              ok: this.$t('buttons.yes'),
              cancel: this.$t('buttons.no'),
              delete: false
            },
            onConfirm: () => {
              this.$modal.visible = false;
              this.goToNextStep();
            }
          });
        });
      });
    },
    goToNextStep() {
      this.setClientData(this.step2);
      this.$emit('goToNextStep');
    },
    goBack() {
      this.$emit('goBack');
    },
    formatTaxNumberStep2(tax_number) {
      this.step2.private.tax_number = String(tax_number).substring(0, 13).replace(/[A-Z]/gi, '')
    }
  }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.dtext {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: center;
  line-height: 25px;
  font-size: 15px;
  margin-left: 15px;
  margin-right: 20px;
}

.dheader {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}

.warning {
  width: 58px;
  height: 52px;
  box-sizing: border-box;
}

</style>
