import {ApiService} from "./api.service";
import Vue from "vue";

export class PropertyService extends ApiService {

    constructor() {
        super();
    }

    canShowFieldStepPlot(state_uid) {
        // Show for Bayern, Niedersachen
        let state_uids = [2, 9];
        return state_uids.includes(parseInt(state_uid))
    }

    canShowStepBuilding(state_uid) {
        // Show "Gebäude(-teile)" tab
        let state_uids = [2, 6, 7, 9];
        return state_uids.includes(parseInt(state_uid))
    }

    canShowStepNonResidential(state_uid) {
        // Show for Bayern, Hamburg and Niedersachsen
        let state_uids = [2, 6, 9];
        return state_uids.includes(parseInt(state_uid))
    }

    isRegistrationLandRegistryDisabled(economicEntityType, propertyType, federalStateUid) {
        economicEntityType = parseInt(economicEntityType);
        propertyType = parseInt(propertyType);
        federalStateUid = parseInt(federalStateUid);

        let federalStateUids = [2, 6, 7, 9];
        if (federalStateUid === 1) {
            return false;
        } else if (federalStateUids.includes(federalStateUid)) {
            if (economicEntityType === 2) {
                return false;
            }
        } else {
            if (propertyType === 5 || propertyType === 6) {
                return false;
            }
        }

        return true;
    }

    isPropertyTypeDisabled(economicEntityType, federalStateUid) {
        economicEntityType = parseInt(economicEntityType);
        federalStateUid = parseInt(federalStateUid);

        let federalStateUids = [2, 6, 7, 9];

        if (economicEntityType === 3) {
            return true;
        }

        if (federalStateUid === 2 || federalStateUid === 9) {
            return true;
        }

        if (economicEntityType === 2 && federalStateUid === 7) {
            return true;
        }
        //Hamburg is always disabled
        if(federalStateUid === 6){
            return true;
        }

        if (federalStateUids.includes(federalStateUid)) {
            if (economicEntityType === 2) {
                return false;
            }
        } else if (federalStateUid !== 1 && economicEntityType !== 1) {
            return false;
        }

        return true;
    }

    listAll(params) {
        if (this.requestToken) {
            this.requestToken.cancel();
        }

        this.requestToken = this.getCancelSource();
        return this.trigger('property/list', {
            'first': 0,
            'rows': params && params.rows ? params.rows : 10,
            'sortField': params && params.sortField ? params.sortField : 'created_at',
            'sortOrder': 1,
            'filters': {
                'txt_filter': params && params.txtFilter ? params.txtFilter : '',
                'uid': params && params.uid ? params.uid : 0,
                'federal_state_uids': params && params.federal_state_uids ? params.federal_state_uids : [],
                'economic_entity_types': params && params.economic_entity_types ? params.economic_entity_types : [],
                'dropdownSearch': params && params.dropdownSearch ? params.dropdownSearch : 0
            }
        }).then((response) => {
            if (response) {
                if (response.data.success && parseInt(response.data.total_records) > 0) {
                    return {
                        "totalRecordCount": response.data.total_records,
                        "list": this.parse_object_data(response.data.records)
                    };
                } else {
                    return {
                        "totalRecordCount": 0,
                        "list": []
                    };
                }
            } else {
                return false;
            }
        })
    }

    listClientProperties(client_uid, params) {
        if (this.requestToken) {
            this.requestToken.cancel();
        }

        this.requestToken = this.getCancelSource();
        return this.trigger('property/list_all', {
            'first': 0,
            'parentType': 'client',
            'parentId': client_uid,
            'filters': {
                'txt_filter': params && params.txtFilter ? params.txtFilter : '',
                'uid': params && params.uid ? params.uid : 0,
                'dropdownSearch': params && params.dropdownSearch ? params.dropdownSearch : 0,
                'selected': params && params.selectedFilter ? params.selectedFilter : '',
                'archived': params && params.archived ? params.archived : 0,
                'always': params && params.always ? params.always : false,
            }
        }).then((response) => {
            if (response) {
                if (response.data.success && parseInt(response.data.total_records) > 0) {
                    return {
                        "totalRecordCount": response.data.total_records,
                        "list": this.parse_object_data(response.data.records)
                    };
                } else {
                    return {
                        "totalRecordCount": 0,
                        "list": []
                    };
                }
            } else {
                return false;
            }
        })
    }

    save(data) {
        return this.trigger('property/save', data, true).then(response => {
            if (response.data.success) {
                this.$store.commit("user/setCurrentUserStatsDataChanged", true);
                return response.data;
            } else {
                if (response.data.invalid_fields) {
                    let invalidFields = this.parse_object_data(response.data.invalid_fields);
                    let errorMessage = response.data.message + ': \n';
                    invalidFields.forEach((arr_value) => {
                        var errorKeyArray = arr_value.split(".");
                        if (typeof this.$i18n.t('properties.steps.' + errorKeyArray[0]) !== 'undefined') {
                            errorMessage += this.$i18n.t('properties.steps.' + errorKeyArray[0]) + ' ';
                        }
                        errorMessage += this.$i18n.t('properties.' + arr_value) + '\n';
                    });
                    this.showToast(errorMessage, "error");
                } else {
                    this.showToast(response.data.message, "error");
                }

                return false;
            }
        });
    }

    duplicate(propertyId, duplicateClientUid) {
        return this.trigger('property/duplicate', {
            "propertyId": propertyId,
            "duplicateClientUid": duplicateClientUid
        }, true).then((response) => {
            if (response.data.success) {
                return response.data.propertyId;
            } else {
                this.showToast(response.data.message, "error");
                return 0;
            }
        })
    }

    csvUpload(files, clientUid) {
        if (files.length > 0 && clientUid > 0) {
            let promises = [];
            for (let file of files) {
                let formData = new FormData();
                formData.append('file', file);
                promises.push(this.trigger('property/uploadFile', formData, false, 'multipart/form-data'));
            }

            return Promise.all(promises).then((responses) => {
                return !!responses[0].data.success;
            });
        } else {
            return false;
        }
    }

    csvImport(clientUid, filePath) {
        return this.trigger('property/csvImport', {
            clientUid: clientUid,
            propertyUid: this.$store.getters["property/getPropertyUid"],
            filePath: filePath,
            attribute: this.$store.getters["property/getImportAttr"]
        }).then(response => {
            return response;
        });
    }

    bdoGrizzlyImport(clientUid, filePath, alreadyTransfered = false) {
        return this.trigger('property/bdoGrizzlyImport', {
            clientUid: clientUid,
            filePath: filePath,
            alreadyTransfered: alreadyTransfered
        }).then(response => {
            return response;
        });
    }

    aareonImport(clientUid, filePath, alreadyTransfered = false) {
        return this.trigger('property/aareonImport', {
            clientUid: clientUid,
            filePath: filePath,
            alreadyTransfered: alreadyTransfered
        }).then(response => {
            return response;
        });
    }

    get(uid, params, show_loader) {
        if (typeof show_loader === 'undefined') {
            show_loader = true;
        }
        let data = {
            uid: uid
        };
        if (typeof params === 'object') {
            data = {...data, ...params}
        }
        return this.trigger('property/get', data, show_loader).then((response) => {
            if (response.data.success) {
                if (response.data.propertyData.id) {
                    response.data.propertyData.id = parseInt(response.data.propertyData.id);
                }

                return response.data.propertyData;
            } else {
                this.$toast.error(response.data.message);
                return [];
            }
        })
    }

    elsterValidate(uid, show_loader) {
        if (typeof show_loader === 'undefined') {
            show_loader = true;
        }
        let data = {
            uid: uid
        };
        return this.trigger('property/elster_validate', data, show_loader).then((response) => {
            return response.data.validatorResult && response.data.validatorResult[uid] ? response.data.validatorResult[uid] : null;
        })
    }

    bulkElsterValidate(uids, show_loader) {
        let data = {
            uids: uids
        };
        return this.trigger('property/elster_validate', data, show_loader).then((response) => {
            return response.data;
        })
    }

    updateDeclarationsElsterValidationProtocol(uid, approvalForUpdating = false, showLoader = true) {
        let data = {
            uid: uid,
            approvalForUpdating: approvalForUpdating
        };

        return this.trigger('property/update_declarations_elster_validation_protocol', data, showLoader).then((response) => {
            return response.data;
        })
    }

    movePropertyParcels(fromPropertyUid, toPropertyUid, parcelUids) {
        let data = {
            parentType: 'property',
            parentId: fromPropertyUid,
            toPropertyUid: toPropertyUid,
            parcelUids: parcelUids
        };

        return this.trigger('parcel/movePropertyParcels', data).then((response) => {
            return response.data;
        })
    }

    moveLandAndForestryParcels(fromPropertyUid, toPropertyUid, parcelUids) {
        let data = {
            parentType: 'property',
            parentId: fromPropertyUid,
            toPropertyUid: toPropertyUid,
            parcelUids: parcelUids
        };

        return this.trigger('parcelslandandforestry/moveLandAndForestryParcels', data).then((response) => {
            return response.data;
        })
    }

    import(rows, mapAttrs, clientUid) {
        return this.trigger('property/import', {
            rows: rows,
            mapAttr: mapAttrs,
            clientUid: clientUid
        }, true);
    }

    export(columns, filters, selectedRecords) {
        let column_data = [];
        columns !== null && columns.map(column => {
            column_data.push({columnOrder: column.columnOrder, field: column.field});
        })
        return this.trigger('property/export', {
            export_filters: filters,
            columns: column_data,
            selectedRecords
        }, true, '', 'blob').then((response) => {
            if (response.headers['download_later']) {
                this.showToast(this.$i18n.t('general.export_in_background'))
            } else {
                this.trigger_download(response);
            }
        });
    }

    // Save Modal data of Property
    saveModalData(data, loader) {
        return this.trigger('property/save_modal', data, loader).then((response) => {
            if (response.data.success) {
                return response.data;
            } else {
                this.$toast.error(response.data.message)
                return [];
            }
        });
    }

    // Get Modal data of Property
    getModalData(data, loader) {
        return this.trigger('property/get_modal', data, loader).then((response) => {
            if (response.data.success) {
                return response.data;
            } else {
                this.$toast.error(response.data.message)
                return [];
            }
        });
    }

    parseDate(timestamp) {
        if (timestamp.trim() !== '') {
            let convertedDate = new Date(parseInt(timestamp) * 1000);
            return convertedDate;
        } else {
            return '';
        }
    }

    // Duplicate row
    duplicateRow(data, loader) {
        return this.trigger('property/duplicate_row', data, loader).then((response) => {
            if (response.data.success) {
                return response.data;
            } else {
                this.$toast.error(response.data.message)
                return [];
            }
        });
    }

    // Get other properties
    getOtherPropertiesModalData(data, loader) {
        return this.trigger('property/get_other_properties_modal', data, loader).then((response) => {
            if (response.data.success) {
                response.data.data = this.parse_object_data(response.data.data);
                return response.data;
            } else {
                this.$toast.error(response.data.message)
                return [];
            }
        });
    }

    // Take over owner data from other property
    takeOverOwnerData(data, loader) {
        return this.trigger('property/take_owner_data', data, loader).then((response) => {
            if (response.data.success) {
                return response.data;
            } else {
                this.$toast.error(response.data.message)
                return [];
            }
        });
    }

    selectNextDeclarationType(propertyId, nextDeclarationType) {
        return this.trigger('property/selectNextDeclarationType', {
            "propertyId": propertyId,
            "nextDeclarationType": nextDeclarationType
        }, true).then((response) => {
            if (response.data.success) {
                return response.data;
            } else {
                this.showToast(response.data.message, "error");
                return [];
            }
        })
    }

    settingsNextDeclaration(propertyId, nextDeclarationType, nextDeclarationReferenceDate) {
        return this.trigger('property/settingsNextDeclaration', {
            propertyId: propertyId,
            nextDeclarationType: nextDeclarationType,
            nextDeclarationReferenceDate: nextDeclarationReferenceDate
        }, true).then((response) => {
            if (response.data.success) {
                return response.data;
            } else {
                this.showToast(response.data.message, 'error');
                return [];
            }
        })
    }

    cancelNextDeclarationType(propertyId, nextDeclarationType) {
        return this.trigger('property/cancelNextDeclarationType', {
            "propertyId": propertyId
        }, true).then((response) => {
            if (response.data.success) {
                return response.data;
            } else {
                this.showToast(response.data.message, "error");
                return [];
            }
        })
    }

    setArchiveProperty(propertyId, archived) {
        return this.trigger('property/setArchiveProperty', {
            'propertyId': propertyId,
            'archived': archived
        }, true).then((response) => {
            if (response.data.success) {
                return response.data;
            } else {
                this.showToast(response.data.message, 'error');
                return [];
            }
        })
    }

    setChangeStatusProperty(propertyId, changeStatus) {
        return this.trigger('property/setChangeStatusProperty', {
            propertyId: propertyId,
            changeStatus: changeStatus
        }, true).then((response) => {
            if (response.data.success) {
                return response.data;
            } else {
                this.showToast(response.data.message, 'error');
                return [];
            }
        })
    }

    // Get submission data for property
    getSubmissions(propertyId) {
        return this.trigger('property/get_submissions', {
            property_uid: propertyId
        }, true).then((response) => {
            if (response.data.success) {
                response.data.data = this.parse_object_data(response.data.data);
                return response.data;
            } else {
                this.$toast.error(response.data.message)
                return [];
            }
        });
    }

    getChanges(propertyId, freezedPropertyId) {
        return this.trigger('property/get_changes', {
            property_uid: propertyId,
            freezed_property_uid: freezedPropertyId
        }, true).then((response) => {
            if (response.data.success) {
                response.data.data = this.parse_object_data(response.data.data);
                return response.data;
            } else {
                this.$toast.error(response.data.message)
                return [];
            }
        });
    }

}
