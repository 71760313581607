<template>
  <MainLayout value="market_value_assessment">
    <template>
      <div class="h-full bg-white pl-7 w-full">
        <PageHeader :title="$t('sidebar.market_value_assessment')"/>

        <div class="flex flex-col justify-center mt-5 mr-7">
          <Card class="bg-green_sidebar text-white mb-8">
            <template #content>
              <div class="flex flex-row items-center">
                <div class="w-full text-left mb-6">
                  <h2 class="text-4xl font-semibold">
                    {{ $t('market_value_assessment.banner.title') }}
                  </h2>

                  <h3 class="text-2xl">
                    {{ $t('market_value_assessment.banner.subtitle') }}
                  </h3>
                </div>

                <div>
                  <a
                    :href="$t('market_value_assessment.banner.button_url')"
                    target="_blank"
                    class="inline-block w-56 p-4 rounded text-2xl font-medium bg-white hover:bg-green"
                  >
                    {{ $t('market_value_assessment.banner.button') }}
                  </a>
                </div>
              </div>
            </template>
          </Card>

          <div class="flex flex-col text-center text-muted_black">
            <p class="block w-full mb-2 text-3xl font-medium">
              {{ $t('market_value_assessment.introduction.line1') }}
            </p>
            <p
              class="block w-full mb-8 text-3xl font-medium"
              v-html="$t('market_value_assessment.introduction.line2')"
            />
            <p class="block w-full mb-6 text-3xl font-semibold">
              {{ $t('market_value_assessment.introduction.line3') }}
            </p>
          </div>

          <div class="flex flex-row p-4 mb-10 bg-gray_light rounded">
            <div class="flex flex-col w-full text-left justify-between">
              <div class="mb-8">
                <h4 class="mb-4 font-semibold text-2xl text-green">
                  {{ $t('market_value_assessment.features.col1.line1.title') }}
                </h4>

                <p
                  class="leading-normal"
                  v-html="$t('market_value_assessment.features.col1.line1.text')"
                />
              </div>

              <div>
                <h4 class="mb-4 font-semibold text-2xl text-green">
                  {{ $t('market_value_assessment.features.col1.line2.title') }}
                </h4>

                <p class="leading-normal">{{ $t('market_value_assessment.features.col1.line2.text') }}</p>
              </div>
            </div>

            <div class="w-full text-left px-6">
              <h4 class="mb-6 font-semibold text-2xl text-green">
                {{ $t('market_value_assessment.features.col2.title') }}
              </h4>

              <ul class="list-inside">
                <li
                  v-for="(entry, index) in featureList"
                  :key="index"
                  class="pl-2 mb-8"
                >
                  <div class="flex flex-row">
                    <img
                      class="w-5 h-fit"
                      :src="getAssetPath('check_green_wavy.svg')"
                    />
                    <span>{{ entry }}</span>
                  </div>
                </li>
              </ul>
            </div>

            <div class="flex flex-col w-full text-left justify-between">
              <div class="mb-8">
                <h4 class="mb-4 font-semibold text-2xl text-green">
                  {{ $t('market_value_assessment.features.col3.line1.title') }}
                </h4>

                <p
                  class="leading-normal"
                  v-html="$t('market_value_assessment.features.col3.line1.text')"
                />
              </div>

              <div>
                <h4 class="mb-4 font-semibold text-2xl text-green">
                  {{ $t('market_value_assessment.features.col3.line2.title') }}
                </h4>

                <p class="leading-normal">{{ $t('market_value_assessment.features.col3.line2.text') }}</p>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 2xl:grid-cols-3 px-8 pt-12 pb-6 mb-8 bg-gray rounded relative">
            <div class="flex flex-row absolute -top-6 left-12">
              <img
                :src="getAssetPath('single-quote-green.svg')"
                class="mr-2"
              />
              <img :src="getAssetPath('single-quote-green.svg')"/>
            </div>

            <div
              v-for="(testimonial, index) in testimonials"
              :key="index"
              class="flex row w-full mb-6"
            >
              <div>
                <div class="testimonial-image-wrapper flex flex-row justify-center items-center mx-3">
                  <img
                    v-for="(image, index) in testimonial.images"
                    :key="index"
                    :src="getAssetPath(image)"
                    :class="{
                      'shared-testimonial-image': testimonial.images.length > 1
                    }"
                  />
                </div>
              </div>

              <div class="flex flex-col text-left justify-between">
                <p class="mb-6 tracking-wide leading-normal italic">„{{ testimonial.quote }}“</p>
                <span>{{ testimonial.from }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-row w-full text-left">
            <img
              :src="getAssetPath('market_value_assessment_steps.png')"
              class="w-1/3 mr-6"
            />
            <div class="p-4">
              <h4 class="text-3xl font-semibold mb-6">
                {{ $t('market_value_assessment.steps.title') }}
              </h4>

              <ul>
                <li
                  v-for="(step, index) in stepsList"
                  :key="index"
                  class="flex flex-row items-center mb-4"
                >
                  <span class="text-9xl font-semibold text-green_sidebar">
                    {{ step.number }}
                  </span>
                  <div class="ml-6">
                    <p class="text-lg">
                      <b>{{ step.lead }}</b>
                      {{ step.text }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="flex flex-row w-full justify-end mb-6">
            <Button
              :text="$t('market_value_assessment.banner.button')"
              class="w-56 rounded text-2xl font-medium"
              @click="openShop"
            />
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import {appMixin} from "@/core/utils/mixin";

import MainLayout from '@/layouts/MainLayout';

import Card from 'primevue/card';
import Button from '@/components/inputs/Button';

export default {
  name: 'MarketValueAssessment',
  components: {
    MainLayout,
    Card,
    Button
  },
  mixins: [appMixin],
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp;
    },
    featureList() {
      return this.$t('market_value_assessment.features.col2.list');
    },
    testimonials() {
      return this.$t('market_value_assessment.testimonials');
    },
    stepsList() {
      return this.$t('market_value_assessment.steps.list');
    }
  },
  created() {
    this.verifyingAccess = true;

    this.checkAccess('market_value_assessment');
  },
  methods: {
    openShop() {
      window.open(this.$t('market_value_assessment.banner.button_url'), '_blank');
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.p-card-body) {
  @apply p-6;
}

:deep(.p-card-content) {
  padding: 0;

  a {
    color: theme('colors.green');
    text-decoration: none;
  }

  a:hover {
    color: theme('colors.white');
  }
}

:deep(.button-wrapper) {
  .button {
    @apply p-8;
  }
}

.testimonial-image-wrapper {
  background-color: theme('colors.white');
  border: 1px solid theme('colors.black');
  border-radius: 100%;
  height: 104px;
  width: 104px;

  .shared-testimonial-image {
    max-width: 42px;
  }
}
</style>
