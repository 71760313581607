<template>
  <div class="flex h-screen">
    <div :class="customClasses">
      <ModalDialog v-show="showUploadDlg" :headerText="$t('documents.upload_file')" @close="showUploadDlg=false">
        <template v-slot:body>
          <div class="flex flex-col justify-start ml-4 ">
            <div class=" pr-8 mt-8 space-y-4">

              <InputSelect v-model="docUploadParentType" :selectedValue="docUploadParentType" :options="docParentTypes"
                           @on-item-selected="updateDocForm"/>
              <div class="flex flex-col space-y-4" v-show="docUploadParentType && docUploadParentType !== ''">
                <InputSelect
                    :label="getSelectLabel"
                    :isRequired="true"
                    :options="docParentRecords"
                    :loading="docParentRecordLoading"
                    :placeholder="$t('general.please_select')"
                    v-model="docParentRecordId"
                    :selectedValue="docParentRecordId"
                    :search-fn="parentRecordsSearch"
                    overlay-append-to="body"
                    :total-record-count="totalParentRecordsCount"
                    :filterable="true"
                    :sort="true"
                />

                <InputUpload ref="uploadInput" v-show="!docParentRecordLoading" :label="$t('documents.select_files')"
                             multiple="multiple"
                             :accept="allowedTypes"
                             :isRequired="true"
                             :isBlackText="true" :showDragAndDrop="false" @select="fileSelect"
                />
              </div>
            </div>
            <div class="flex mt-10 mb-8  justify-end space-x-4 mr-6">
              <Button class="" :text="$t('buttons.save')" @click="upload" :disabled="getButtonState"/>
              <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="cancelUpload"/>
            </div>
          </div>
        </template>
      </ModalDialog>
      <Sidebar @toggle="handleToggle" :menus="visibleMenus" :navClosed="bigNav" @clickSetting="handleSettingClick"
               @click="handleClick"
               @popClick="handlePopClick"
               :settingsSelected="settingsSelected"/>
    </div>

    <div class="flex flex-col overflow-x-hidden container-main flex-auto">
      <form autocomplete="off" @submit.prevent class="h-full">
        <slot class="bg-white"/>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {changeSidebarTheme} from "@/core/utils/helpers";
import {appMixin} from "@/core/utils/mixin";
import {ClientService} from "@/services/client.service";
import {DocumentService} from "@/services/document.service";
import {PropertyService} from "@/services/property.service";
import {DeclarationService} from "@/services/declaration.service";

export default {
  name: "MainLayout",
  props: ['value'],
  mixins: [appMixin],
  data() {
    return {
      clientService: new ClientService(),
      docService: new DocumentService(),
      propertyService: new PropertyService(),
      declarationService: new DeclarationService(),
      docUploadParentType: '',
      docParentTypes: [
        {
          name: this.$t('sidebar.add_popup.declaration'),
          code: "declaration"
        },
        {
          name: this.$t('sidebar.add_popup.property'),
          code: "property"
        },
        {
          name: this.$t('sidebar.add_popup.client'),
          code: "client"
        }
      ],
      docParentRecordLoading: false,
      docParentRecords: [],
      docParentRecordId: '0',
      totalParentRecordsCount: 0,
      showUploadDlg: false,
      allowedTypes: ".pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.txt,.xml,.jpg,.jpeg,.png",
      files: [],
      currentComponent: 'dashboard',
      bigNav: true,
      settingsSelected: false,
      menus: [
        {
          name: this.$t('sidebar.home'),
          isActive: true,
          icon: this.getAssetPath('home.svg'),
          component: 'Dashboard',
          slug: 'home'
        },
        {
          name: this.$t('sidebar.declaration'),
          isActive: false,
          icon: this.getAssetPath('declaration.svg'),
          component: 'Declarations',
          slug: 'declaration',
          submenu: this.$isBusiness ? [
            {
              name: this.$t('sidebar.declaration_submenu.draft'),
              component: 'Draft',
              slug: 'draft',
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.release_requested'),
              component: 'ReleaseRequest',
              slug: 'release_request',
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.request'),
              component: 'DeclarationRequest',
              slug: "elster_transmission",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.dispute'),
              component: 'DeclarationDespute',
              slug: "elster_complete",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.notification_received'),
              component: 'DeclarationCompleted',
              slug: "notification_received",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.contradiction'),
              component: 'DeclarationCompleted',
              slug: "contradiction",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.mass_appeal'),
              component: 'DeclarationCompleted',
              slug: "mass_appeal",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.complete'),
              component: 'DeclarationCompleted',
              slug: "completed",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.archived'),
              component: 'DeclarationCompleted',
              isActive: false,
              slug: "archived"
            }
          ] : [
            {
              name: this.$t('sidebar.declaration_submenu.draft'),
              component: 'Draft',
              slug: 'draft',
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.in_progress'),
              component: 'DeclarationDetails',
              slug: 'in_progress',
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.release'),
              component: 'Release',
              slug: 'chancery_release',
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.request_client'),
              component: 'DeclarationClient',
              slug: 'tenant_requested',
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.requested_client'),
              component: 'DeclarationClient',
              slug: 'client_requested',
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.request'),
              component: 'DeclarationRequest',
              slug: "elster_transmission",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.dispute'),
              component: 'DeclarationDespute',
              slug: "elster_complete",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.notification_received'),
              component: 'DeclarationCompleted',
              slug: "notification_received",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.contradiction'),
              component: 'DeclarationCompleted',
              slug: "contradiction",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.mass_appeal'),
              component: 'DeclarationCompleted',
              slug: "mass_appeal",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.complete'),
              component: 'DeclarationCompleted',
              slug: "completed",
              isActive: false,
            },
            {
              name: this.$t('sidebar.declaration_submenu.archived'),
              component: 'DeclarationCompleted',
              isActive: false,
              slug: "archived"
            }
          ]
        },
        {
          name: this.$t('sidebar.properties'),
          isActive: false,
          icon: this.getAssetPath('property_menu.svg'),
          component: 'Properties',
          slug: 'properties',
          submenu: [{
              name: this.$t('sidebar.archived_properties'),
              component: 'ArchivedProperties',
              slug: 'archived_properties',
              isActive: false,
          }]
        },
        {
          name: this.$t('sidebar.change_management'),
          isActive: false,
          icon: this.getAssetPath('change_management_menu.svg'),
          component: 'ChangeManagement',
          slug: 'change_management',
          submenu: [{
              name: this.$t('sidebar.change_management_data_query'),
              component: 'ListDataQuery',
              slug: 'list_data_query',
              isActive: false
            }]
        },
        {
          name: this.$t('sidebar.clients'),
          isActive: false,
          icon: this.getAssetPath('clients.svg'),
          component: 'Clients',
          slug: 'clients'
        },
        {
          name: this.$t('sidebar.document'),
          isActive: false,
          icon: this.getAssetPath('document_menu.svg'),
          component: 'Documents',
          slug: 'documents',
          submenu: [
            {
              name: this.$t('sidebar.document_sub.overview'),
              component: 'Documents',
              slug: 'list',
              isActive: false
            },
            {
              name: this.$t('sidebar.document_sub.upload_notice'),
              component: 'UploadNotice',
              slug: 'upload_notice',
              isActive: false
            },
            {
              name: this.$t('sidebar.document_sub.review_notice'),
              component: 'ReviewNotice',
              slug: 'review_notice',
              isActive: false
            }
          ]
        },
        {
          name: this.$t('sidebar.datev_dms'),
          isActive: false,
          icon: this.getAssetPath('dms2.svg'),
          component: 'Dms',
          slug: 'dmstransfer'
        },
        {
          name: this.$t('sidebar.datev_ggw'),
          isActive: false,
          icon: this.getAssetPath('doc_ggw.svg'),
          component: 'Ggw',
          slug: 'datevggw'
        },
        {
          name: this.$t('sidebar.addison_ggw'),
          isActive: false,
          icon: this.getAssetPath('doc_ggw.svg'),
          component: 'AddisonGgw',
          slug: 'addisonggw'
        },
        {
          name: this.$t('sidebar.deadline'),
          isActive: false,
          icon: this.getAssetPath('deadline.svg'),
          component: 'Deadline',
          slug: 'deadline'
        },
        {
          name: this.$t('sidebar.market_value_assessment'),
          isActive: false,
          icon: this.getAssetPath('market_value_assessment.svg'),
          component: 'MarketValueAssessment',
          slug: 'market_value_assessment'
        },
        {
          name: this.$t('sidebar.report'),
          isActive: false,
          icon: this.getAssetPath('reports.svg'),
          component: 'Reports',
          slug: 'reports'
        },
        {
          name: this.$t('sidebar.changelog'),
          isActive: false,
          icon: this.getAssetPath('info.svg'),
          slug: 'changelog'
        },
        {
          name: this.$t('sidebar.help'),
          isActive: false,
          icon: this.getAssetPath('help.svg'),
          slug: 'help'
        },
      ]
    }
  },
  computed: {
    ...mapGetters("client", ["getClientDataChanged"]),
    ...mapGetters("property", ["getPropertyDataChanged"]),
    ...mapGetters("declaration", ["getDeclarationDataChanged"]),
    getButtonState() {
      return !(this.docParentRecordId !== '0' && this.docUploadParentType !== '' && this.files.length > 0);
    },
    getSelectLabel() {
      if (this.docUploadParentType && this.docUploadParentType !== '') {
        let found = this.docParentTypes.filter((o) => {
          if (o.code === this.docUploadParentType) {
            return o;
          }
        });

        if (found && found.length > 0) {
          return found[0].name;
        }
      }

      return '';
    },
    customClasses() {
      return [{'nav-open': this.bigNav === true}, {'nav-close': this.bigNav === false}];
    },
    visibleMenus() {
      return this.menus.filter((o) => {
        let menuId = o.slug ? o.slug : '';
        if (menuId === '' || this.hasAccess(menuId)) {
          return true;
        }
      })
    }
  },
  watch: {
    '$route'(to) {
      this.activateSubMenu(to.params.comp);
    }
  },
  mounted() {
    if (process.env.VUE_APP_DEV_VARIANT === 'business') {
      changeSidebarTheme('#f1f3f5')
    }

    this.currentComponent = this.$props.value
    let name = this.$props.value;
    let index = this.menus.findIndex(item => item.slug === name)
    this.menus = this.menus.map((x, i) => {
      return i === index ? {...x, isActive: true, showSubMenu: x.submenu} : {...x, isActive: false}
    });

    this.activateSubMenu(this.$route.params.comp);

    if (this.$props.value === 'settings') {
      this.settingsSelected = true;
      this.currentComponent = 'AccountSettings'
      this.menus = this.menus.map((x) => {
        return {...x, isActive: false}
      })
    }
    this.chart()
  },
  methods: {
    ...mapGetters("user", ["getUserRole"]),
    ...mapMutations("user", ["resetCurrentUser"]),
    ...mapMutations("property", ["setPropertyDataChanged"]),
    ...mapMutations("declaration", ["setDeclarationDataChanged"]),
    ...mapMutations("client", ["setClientDataChanged"]),
    activateSubMenu(slug) {
      if(slug) {
        // find related submenu
        this.menus = this.menus.map((x) => {
          if(x.submenu && x.slug.toLowerCase() === this.currentComponent.toLowerCase()) {
            let index = x.submenu.findIndex(item => item.slug === slug);
            if(index >= 0) {
              x.submenu = x.submenu.map((sub) => {
                sub.isActive = false;
                return sub;
              })
              x.submenu[index].isActive = true;
              x.isActive = false;
              x.showSubMenu = true;
            }
          }

          return x;
        });
      }
    },
    handlePopClick(item) {
      switch (item) {
        case "document":
          this.showUploadDlg = true;
          this.docParentRecords = [];
          this.docParentRecordLoading = true;
          this.docParentRecordId = '0';
          this.docUploadParentType = '';
          this.$refs.uploadInput.clear();
          break;
        case "client":
          this.$router.push('/client/add')
          break;
        case "properties":
          this.$router.push('/property/add')
          break;
        case "declaration":
          this.$router.push('/declaration/AddDeclaration')
          break;
      }
    },
    handleComponentClick(data) {
      switch (data) {
        case 'Cancel':
          this.currentComponent = 'UserManagement';
          //  this.$router.push('/home/' + 'usermanagement')
          // this.$router.push({name: 'Home', params: {id: '1'}})
          break;

          ////user Menu
        case 'add_user':
          this.currentComponent = 'adduser';
          this.$router.push('/home/' + 'usermanagement')
          break;
        case 'edit_user':
          this.currentComponent = 'EditUser';
          this.$router.push('/home/' + 'usermanagement')
          break;

          ///Client Menu
        case 'add_client':
          this.currentComponent = 'CreateNewClient';

          break;
        case 'edit_client':
          this.currentComponent = 'EditClient';

          break;

          ///Property menu
        case 'add_property':
          this.currentComponent = 'AddProperty';

          break;
        case 'edit_property':
          this.currentComponent = 'EditProperty';

          break;
        case 'property_add_finish':
          this.currentComponent = 'Properties';

          break;
          ////Report Menu
        case 'edit_report':
        case 'create_report':
          this.currentComponent = 'CreateReport';

          break;
        case 'report_add_finish':
          this.currentComponent = 'Reports';

          break;
        case 'view_report':
          this.currentComponent = 'ViewReport';
          break;
        case 'edit_declaration':
        case 'add_declaration':
          this.currentComponent = 'AddDeclaration';
          break;
      }
    },
    handleSettingClick(data) {
      this.settingsSelected = true;
      this.currentComponent = 'AccountSettings'//'LogProtocol'//'UserManagement';
      this.menus = this.menus.map((x) => {
        return {...x, isActive: false}
      })

      switch (data) {
        case 'user_management': {
          this.$router.push('/setting/usermanagement')
          break;
        }
        case 'account_settings': {
          this.$router.push('/setting/accountsettings')
          break;
        }
        case 'branches': {
          this.$router.push('/setting/branches')
          break;
        }
        case 'powers_of_attorney': {
          this.$router.push('/setting/powersofattorney')
          break;
        }
        case 'departments': {
          this.$router.push('/setting/departments')
          break;
        }
        case 'system_settings': {
          this.$router.push('/setting/systemsettings')
          break;
        }
        case 'protocol': {
          this.$router.push('/setting/protocol')
          break;
        }
        case 'communications': {
          this.$router.push('/setting/communications')
          break;
        }
        case 'mailbook': {
          this.$router.push('/setting/mailbook')
          break;
        }
        case 'billing': {
          // this.$toast.info(this.$t('sidebar.popup.billing_msg'));
          this.$router.push('/setting/billing')
          break;
        }
        case 'billing_trial': {
          this.$router.push('/setting/billingTrial')
          break;
        }
        case 'reports': {
          this.$router.push('/report/reports')
          break;
        }
        case 'draft': {
          this.$router.push('/declaration/draft')
          break;
        }
        case 'release_request': {
          this.$router.push('/declaration/ReleaseRequest')
          break;
        }
        case 'in_progress': {
          this.$router.push('/declaration/DeclarationDetails')
          break;
        }
        case 'add_branch': {
          this.$router.push('/branch/add')
          break;
        }
        case 'edit_branch': {
          this.$router.push('/branch/edit')
          break;
        }
        case 'logout': {
          this.logout();
          break;
        }
      }
    },
    handleClickHandler(data) {
      this.settingsSelected = false
      this.currentComponent = data.data.parent && data.data.parent.component ? data.data.parent.component : data.data.component
      let name = data.data.slug
      let index = this.menus.findIndex(item => item.slug === name)
      if (data.submenuClicked) {
        let menuIndex = this.menus.findIndex(item => item.slug === data.data.parent.slug)
        if(menuIndex >= 0) {
          this.menus[menuIndex].submenu = this.menus[menuIndex].submenu.map((x, i) => {
            let sIndex = this.menus[menuIndex].submenu.findIndex(item => item.slug === data.data.slug)
            return i === sIndex ? {...x, isActive: true, showSubMenu: true} : {...x, isActive: false, showSubMenu: false}
          });
          this.menus[menuIndex].isActive = false;
        }
      } else {
        this.menus = this.menus.map((x, i) => {
          if(x.submenu) {
            x.submenu = x.submenu.map((item) => {
              item.isActive = false;
              return item;
            });
          }
          return i === index ? {...x, isActive: true, showSubMenu: x.submenu} : {...x, isActive: false}
        });
      }

      switch (data.data.slug) {
        case 'home': {
          this.$router.push('/home');
          break;
        }
        case 'declaration': {
          this.$router.push('/declaration/declarations');
          break;
        }
        case 'clients': {
          this.$router.push('/client/list');
          break;
        }
        case 'change_management': {
          if (this.$route.path != '/changemanagement/list') {
            this.$router.push('/changemanagement/list');
          }
          break;
        }
        case 'list_data_query': {
          if (this.$route.path != '/changemanagement/list_data_query') {
            this.$router.push('/changemanagement/list_data_query');
          }
          break;
        }
        case 'documents':
        case 'list': {
          this.$router.push('/documents/list');
          break;
        }
        case 'upload_notice': {
          this.$router.push('/documents/upload_notice');
          break;
        }
        case 'review_notice': {
          this.$router.push('/documents/review_notice');
          break;
        }
        case 'dms': {
          this.$router.push('/datev/setup_branches');
          break;
        }
        case 'properties': {
          this.$router.push('/property/list');
          break;
        }
        case 'archived_properties': {
          this.$router.push('/property/ArchivedProperties');
          break;
        }
        case 'dmstransfer': {
          this.$router.push('/datev/dms');
          break;
        }
        case 'datevggw': {
          this.$router.push('/datev/ggw');
          break;
        }
        case 'addisonggw': {
          this.$router.push('/addison/AddisonGgw');
          break;
        }
        case 'reports': {
          this.$router.push('/report/reports');
          break;
        }
        case 'deadline': {
          this.$router.push('/deadline/list');
          break;
        }
        case 'market_value_assessment': {
          this.$router.push('/market_value_assessment');
          break;
        }
        case 'draft': {
          this.$router.push('/declaration/draft');
          break;
        }
        case 'release_request': {
          this.$router.push('/declaration/ReleaseRequest');
          break;
        }
        case 'in_progress': {
          this.$router.push('/declaration/requested');
          break;
        }
        case 'chancery_release': {
          this.$router.push('/declaration/Release');
          break;
        }
        case 'tenant_requested': {
          this.$router.push('/declaration/tenantrequested');
          break;
        }
        case 'client_requested': {
          this.$router.push('/declaration/client_requested');
          break;
        }
        case 'elster_transmission': {
          this.$router.push('/declaration/elstertransmisstion');
          break;
        }
        case 'elster_complete': {
          this.$router.push('/declaration/elstercomplete');
          break;
        }
        case 'notification_received': {
          this.$router.push('/declaration/notificationreceived');
          break;
        }
        case 'contradiction': {
          this.$router.push('/declaration/contradiction');
          break;
        }
        case 'mass_appeal': {
          this.$router.push('/declaration/mass_appeal');
          break;
        }
        case 'completed': {
          this.$router.push('/declaration/completed');
          break;
        }
        case 'archived': {
          this.$router.push('/declaration/archived');
          break;
        }
        case 'help': {
            let supportButton = document.getElementById('atlwdg-trigger')
            supportButton.click();
          break;
        }
        case 'changelog': {
          window.open('https://changelog.grundsteuer-digital.de/', '_blank');
          break;
        }
      }
    },
    handleClick(data) {
      if (this.getClientDataChanged || this.getPropertyDataChanged || this.getDeclarationDataChanged) {
        this.$modal.show({
          headerText: this.$t('general.unsaved-change-exist-header'),
          text: this.$t('general.unsaved-change-exist-info'),
          showClose: true,
          canEscape: true,
          inverseBtnColor: true,
          buttons: {
            ok: this.$t('general.leave-page'),
            cancel: this.$t('general.cancel'),
            delete: false
          },
          onConfirm: () => {
            this.setClientDataChanged(false);
            this.setPropertyDataChanged(false);
            this.setDeclarationDataChanged(false);
            this.handleClickHandler(data);
          },
          onCancel: () => {
            return;
          },
          onHide: () => {
            return;
          }
        });
      } else {
        this.handleClickHandler(data);
      }
    },
    chart() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }, {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }
        ]
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    handleToggle() {
      this.bigNav = !this.bigNav
    },
    toggle() {
      this.bigNav = !this.bigNav
    },
    updateDocForm(e) {
      this.files = [];
      this.docParentRecords = [];
      this.totalParentRecordsCount = 0;
      this.$refs.uploadInput.clear();
      this.docParentRecordLoading = true;

      if (e.code === 'client') {
        this.clientService.listMyClients().then((clients) => {
          if (clients) {
            if (clients.list.length > 0) {
              this.totalParentRecordsCount = clients.totalRecordCount;
              this.docParentRecords = clients.list.map((o) => {
                return {
                  "code": o.prim_uid,
                  "name": o.client_id + ' - ' + o.client_name
                }
              });
              this.docParentRecordLoading = false;
            } else {
              this.$api.showToast(this.$t('general.errors.please_add_client'), "error");
              this.docUploadParentType = '';
            }
          }
        })
      } else if (e.code === 'property') {
        this.propertyService.listAll().then((properties) => {
          if (properties) {
            if(properties.list.length > 0) {
              this.totalParentRecordsCount = properties.totalRecordCount;
              this.docParentRecords = properties.list.map((list) => {
                return {
                  "name": list.name,
                  "code": list.prim_uid
                }
              })
              this.docParentRecordLoading = false;
            } else {
              this.$api.showToast(this.$t('general.errors.please_add_property'), "error");
              this.docUploadParentType = '';
            }
          }
        })
      } else if (e.code === 'declaration') {
        this.declarationService.listAll({"dropdownSearch": 1}).then((declarations) => {
          if (declarations) {
            if(declarations.list.length > 0) {
              this.totalParentRecordsCount = declarations.totalRecordCount;
              this.docParentRecords = declarations.list.map((list) => {
                return {
                  "name": list.declarationNumber + ' - ' + list.property.name,
                  "code": list.id
                }
              })
              this.docParentRecordLoading = false;
            } else {
              this.$api.showToast(this.$t('general.errors.please_add_declaration'), "error");
              this.docUploadParentType = '';
            }
          }
        })
      }
    },
    parentRecordsSearch(params) {
      if (this.docUploadParentType === 'client') {
        return this.clientService.listMyClients(params).then((clients) => {
          if (clients && clients.list) {
            return clients.list.map((o) => {
              return {
                "code": o.prim_uid,
                "name": o.client_id + ' - ' + o.client_name
              }
            });
          } else {
            return false;
          }
        })
      } else if (this.docUploadParentType === 'property') {
        return this.propertyService.listAll(params).then((properties) => {
          if (properties && properties.list) {
            return properties.list.map((list) => {
              return {
                "name": list.name,
                "code": list.prim_uid
              }
            })
          } else {
            return false;
          }
        })
      } else if (this.docUploadParentType === 'declaration') {
        return this.declarationService.listAll(params).then((declarations) => {
          if (declarations && declarations.list) {
            return declarations.list.map((list) => {
              return {
                "name": list.declarationNumber + ' - ' + list.property.name,
                "code": list.id
              }
            })
          } else {
            return false;
          }
        })
      }
    },
    cancelUpload() {
      this.files = [];
      this.showUploadDlg = false;
    },
    fileSelect(e) {
      this.files = [];
      if (e.files.length > 0) {
        this.files = e.files;
      }
    },
    async upload() {
      if (this.docParentRecordId !== '0' && this.docUploadParentType !== '' && this.files.length > 0) {
        if (this.files.length > 0) {
          this.docService.setDefaultParams({
            "parentType": this.docUploadParentType,
            "parentId": this.docParentRecordId,
          })
          if (await this.docService.upload(this.files)) {
            this.showUploadDlg = false;
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.scroll-height {
  height: 375px;
}

.scroll-height-table {
  height: 450px;
}

.rectangle-right {
  width: 40%;
  height: 561px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #d1d1d1;
  text-align: center;
  line-height: normal;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
}

.notification-button {


  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: rgba(204, 204, 204, 1);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  //padding: 6px 17px 7px 18px;


}

.container-main {
  background-color: white;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  width: 100%;
}

//.nav-open {
//  width: 300px;
//  animation: slide-right .2s;
//}
//
//.nav-close {
//  width: 56px;
//  animation: slide-left .2s reverse;
//}

@-webkit-keyframes slide-left {
  from {
    width: 56px;
  }

  to {

    width: 300px;
  }
}

@-webkit-keyframes slide-right {
  from {
    width: 56px;
  }

  to {


    width: 300px;
  }
}


.add-cards {
  height: 25px;
  margin-top: 10px;
}

.icon-add {
  width: 18px;
  height: 18px;
}

.icon-text {
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;

}

/*.progress-bar {
  width: 90%;
  height: 18px;
  border-radius: 10px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

progress {

  height: 18px;
  border-radius: 10px;
  background-color: #229d56;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

progress::-webkit-progress-bar {
  background-color: #f2f2f2;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: #229d56;
  border-radius: 10px;
  // box-shadow: 1px 1px 5px 3px rgba( 255, 0, 0, 0.8 );
}

progress::-moz-progress-bar {
  !* style rules *!
}*/

.rectangle-chart {
  width: 95%;
  height: 100%;
  margin-top: 20px;
  margin-right: 50px;
  //background-color: #ffffff;
  //  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #d1d1d1;
  text-align: center;
  //line-height: normal;
  // box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
}

.rectangle {
  width: 59%;
  height: 561px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #d1d1d1;
  text-align: center;
  line-height: normal;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
}

.checkbox {
  height: 37px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  border-radius: 2px;
  background-color: #ffffff;

  &.checked {
    color: black;
    text-decoration: line-through
  }

}

.account {
  height: 440px;
  scrollbar-width: none;
}

//::-webkit-scrollbar {
//  display: none;
//}


.welcome {
  font-size: 26px;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  margin-left: 10px;
  padding-left: 20px;
  float: left;
}


.top-banner {
  height: 150px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  //text-align: center;
  line-height: normal;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
  padding-top: 30px;
}

.sidebar {
  width: 300px;
  height: 100%;
  //overflow-y: hidden;
  background-color: $green-dashboard;
  color: #333333;
}

canvas {
  height: 100px;
}

.image {
  height: 105px;
}

//.enter-login {
//  @include segoe-regular;
//  width: 100%;
//  height: 34px;
//  border: 2px solid white;
//  background-color: transparent;
//  box-sizing: border-box;
//  color: white;
//  text-align: left;
//
//  &:focus {
//    border: 2px solid white;
//  }
//
//  background-image: url('~@/assets/search.svg');
//  background-repeat: no-repeat;
//  background-position: right;
//  padding: 5px;
//  background-origin: content-box;
//
//
//}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

//.child-img {
//  border-radius: 50%;
//  height: 15px;
//  width: 15px;
//  position: absolute;
//  top: -5px;
//  left: 8px;
//  right: 0;
//}

.notification {
  @include segoe-regular;
  font-size: 10px;
}

//.nav-open {
//  width: 300px;
// // animation: slide-right .2s;
//}
//
//.nav-close {
//  width: 56px;
// // animation: slide-left .2s reverse;
//}
</style>
